import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => {

  const [privacyUrl, setPrivacyUrl] = useState(`https://www.iubenda.com/privacy-policy/29997634`)
  // const [cookieUrl, setCookieUrl] = useState(`https://www.iubenda.com/privacy-policy/29997634/cookie-policy`)
  useEffect(() => {
    const lang = localStorage.getItem('gatsby-intl-language') || 'it'

    if (lang === 'fr') setPrivacyUrl(`https://www.iubenda.com/privacy-policy/25073014`)
    if (lang === 'es') setPrivacyUrl('https://www.iubenda.com/privacy-policy/82943158')
    if (lang === 'de') setPrivacyUrl(`https://www.iubenda.com/privacy-policy/86534125`)
    if (lang === 'en') setPrivacyUrl(`https://www.iubenda.com/privacy-policy/84561116`)
    // setCookieUrl(privacyUrl + '/cookie-policy')
  }, [])

  return (
    <Layout>
      <Seo title="Informativa privacy" />
      <iframe title="Iubenda Privacy Policy" src={privacyUrl} width="100%" height="1024px" border="0" frameBorder={0}/>
    </Layout>
  )
}

export default Privacy
